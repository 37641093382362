.reserv-title {
    font-size: 30px;
}
.reserv-title-2 {
    font-size: 20px;
    font-weight: 300;
}
.res-address {
    border: solid 1px #F6A413;
    border-radius: 10px;
    background-color: white;
    padding: 7px;
}


.box-tip {
    background-color: #ddd;
    padding: 20px;
    border-radius: 10px;
    border: solid 2px #F6A413; 
}

.box-tip .accordion-item {
    background: transparent;
}
.box-tip button.accordion-button {
    background: transparent !important;
}


.res-form {
    background-color: white;
    border-radius: 7px;
    padding: 30px 15px;
    box-shadow: 1px 1px 1px #ddd;
}

.res-steps {
    text-align: start;
    list-style: none;
    border-right: solid 1px black;
}
.line-res {
    height: 1px;
    width: 20px;
    background-color: black;
    padding: 1px 20px;
}

.res-step {
    margin: 80px 0px;
    padding: 5px;

}
.res-steps-col {
    border-left: solid 1px black;

}


.res-step-active {
    background: linear-gradient(45deg, #f6a413ad, white);
}





@media only screen and (max-width: 700px) {
    .reserv-title {
        font-size: 18px;
    }
    .reserv-title-2 {
        font-size: 16px;
    }
    .box-tip {
        background-color: #ddd;
        padding: 10px;
        border-radius: 10px;
        border: solid 2px #F6A413; 
    }
    
}
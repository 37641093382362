.arrow-2-s {
    position: absolute;
    width: 130px;
    top: 39px;
    right: 166px;
}
.arrow-3-s {
    position: absolute;
    width: 130px;
    top: 40px;
    right: 46%;
}
.arrow-4-s {
    position: absolute;
    width: 130px;
    top: 39px;
    left: 209px;
}

.img-cats-s {
    width: 60%;
}
.text-arrows {
    font-weight: 200;
    word-spacing: 46px;
}
.mt-cat {
    margin-top: 100px;
}
@media only screen and (max-width: 700px) {
    
    .arrow-2-s {
        width: 55px;
        right: 35px;
        top: 30px;
    
    }
    .arrow-3-s {
        width: 55px;
        top: 30px;
    }
    .arrow-4-s {
        width: 55px;
        left: 35px;
        top: 30px;
    }
    .mt-cat {
        margin-top: 20px;
    }
    .text-arrows-s {
        font-size: 23px;
        word-spacing: 19px;
    }
    .img-cats-s {
        width: 90%;
    }
  
}
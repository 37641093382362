@media only screen and (min-width: 800px) and (max-width: 950px) {
    .footer {
        border-radius: 25px 25px 0px 0px !important;
    }
}

@media only screen and (max-width: 600px) {

    .quik-access li {
        font-size: 12px;
    }
    .footer-p {
        font-weight: 300 !important;
    }

}

.footer {
    background-color: white;
    border-radius: 25px 25px 0px 0px;
    position: relative;
    z-index: 99;
}

.F-title::after {
    content: "";
    top: 115%;
    height: 2px;
    width: 72%;
    background: linear-gradient(to left, var(--main), white);
    position: absolute;
    right: -20px !important;
}

.after-two::after {
    top: 37% !important;
}


.logo-footer {
    -webkit-text-stroke: 2.5px;
}

.F-category li {
    list-style: none;
}

.F-category li {
    position: relative;
    font-size: 15px;
}

.access-phone {
    list-style: none;
    font-size: 12px;
}
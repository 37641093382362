.slidee::after {
    content: "";
    position: absolute;
    width: 100%;
    margin: auto;
    inset: 0;
    border-radius: 20px;
    padding: 3px;
    background: linear-gradient(91deg, #ffffff00, #F6A413);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    mask-composite: exclude;
    z-index: -10;
}

.slidee {
    height: 442px;
    padding: 0px 4px;
    position: relative;
}

.limit-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3; 
    -webkit-box-orient: vertical;
    text-align: justify;
}

.afterBlog::after {
    content: "";
    position: absolute;
    width: 100%;
    margin: auto;
    inset: 0;
    border-radius: 20px;
    padding: 3px;
    background: linear-gradient(91deg, #ffffff00, #F6A413);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    mask-composite: exclude;
    z-index: -10;
}

.afterBlog {
    height: 340px;
    padding: 0px 4px;
    position: relative;
    padding: 19px;
}

.logo-blog {
    position: absolute;
    top: 90%;
    right: 20px;
    background-color: white;
    border-radius: 50%;
    border: solid 2px #f6a413;
    max-width: 71px;
    padding: 14px 7px 14px 7px;
}
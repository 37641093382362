
.footer-blog-card {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    border-radius: 20px;
    padding: 2px 20px 0px 20px;
    margin: 20px 5px ;
}

.bg-blog {
    border-radius: 15px;
    background-size: cover;
}

.blog-slider-home .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-width: 304px;
    max-width: 304px;
    min-height: 150px;
    max-height: 150px;
}

.blog-home-slider {
    min-height: 245px;
}

@media only screen and (max-width: 600px) {
    .bg-blog {
        background-size: cover;
    }
    .blog-slider-home .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        min-width: 172px;
        max-width: 172px;
        min-height: 125px;
        max-height: 125px;
    }
    .blog-home-slider {
        min-height: 215px;
    }
}

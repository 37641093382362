@media only screen and (max-width: 820px) {
    
    .cart-name {
        order:-1 ;
        font-weight: bold !important;
    }
    .cart-count {
        order: -2;
    }
    .cart-remove {
        order:-1 ;
        text-align: end;

    }
    .btn-count-mobile {
        border-top:solid 1px #F6A413 ;
        border-bottom:solid 1px #F6A413 ;
        padding: 15px 0px;
    }
}






.cart-title {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart-title::after {
    content: "";
    height: 3px;
    background-color: #F6A413;
    width: 100%;
    position: absolute;
    top: 150%;
    right: 0px;

}

.box-discount {
    border: solid 1.5px #F6A413;
    border-radius: 5px;
    padding: 9px 6px 6px 6px;
}

.input-discount {
    border: none;
    width: 60px;
    background-color: transparent;
    font-weight: 300;
    text-align: center;
}

.input-discount:focus-visible {
    border: none !important;
    outline: none;
}

.count-btns {
    background: white;
    padding: 0px 10px;
    font-size: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 0px #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.box-ptc {
    border-bottom: solid 1px #F6A413;
}

.box-information {
    background: white;
    border: solid 2px #F6A413;
    padding: 0px 20px;
    border-radius: 5px;
}

.countine-btn {
    background-color: rgb(0, 161, 0);
    border-radius: 8px;
    color: white;
    width: 100%;
    border: none;
    margin-bottom: 35px;
    padding: 8px 15px !important;
    text-align: start;
    font-size: 18px;
}

.cart-name {
    font-weight: 300;
}
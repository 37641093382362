.box-404-images {
    position: relative;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.img-not {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 100px;
}

.img-text {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 200px;
}

.img-logo {
    width: 330px;
}

.serarch-erroepage {
    width: 70%;
    padding: 16px;
    font-size: 17px;
}

.box-rows {
    height: 100px;
    position: relative;
}

.arrow-2 {
    position: absolute;
    width: 130px;
    top: 10px;
    right: 240px;

}
.arrow-3 {
    position: absolute;
    width: 130px;
    top: 40px;
    right: 46%;
}
.arrow-4 {
    position: absolute;
    width: 130px;
    top: 20px;
    left: 240px;
}

.img-cats {
    width: 50%;
}
.text-arrows {
    font-weight: 200;
    word-spacing: 46px;
}
@media only screen and (max-width: 700px) {
    .img-not {
        left: 30px;
        width: 70px;
    }

    .img-text {
        right: 0px;
        width: 150px;
    }
    
    .img-logo {
        width: 290px;
    }
    .arrow-2 {
        width: 55px;
        right: 35px;
        top: 30px;
    
    }
    .arrow-3 {
        width: 55px;
        top: 30px;
    }
    .arrow-4 {
        width: 55px;
        left: 35px;
        top: 30px;
    }
    .img-cats {
        width: 80%;
    }
    .box-rows {
        height: 80px;
    }
    .serarch-erroepage {
        width: 100%;
        padding: 9px;
        font-size: 13px;
    }
    .text-arrows {
        font-weight: 0;
        word-spacing: 0;
        letter-spacing: 0;
    }
    .box-404-images {
        margin-top: 0px;
        height: 250px;
    }
}
.input-review {
    width: 100%;
    border: solid 1px #F6A413;
    border-radius: 10px;
    padding: 7px 0px;
}

.review-send {
    width: 100%;
    background-color: #F6A413;
    color: white;
    border-radius: 10px;
    border: none;
    padding: 7px 0px;
    font-size: 14px;
}

.review-reating {
    background-color: white;
    border: solid 1px #F6A413;
    border-radius: 10px;
    width: 100%;
    text-align: start;
}

.answer {
    width: 80%;
    margin: 0px auto;
    position: relative;
}

.answer::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 40px;
    background-color: #F6A413;
    top: -40px;
    right: 34px;
}

@media only screen and (max-width: 700px) {
    .answer {
        width: 80%;
        margin: 0px auto;
        position: relative;
    }
    

}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: black;
}



header {
  max-height: 197px;
  background-color: #F4f4f4;
}

.search-input {
  margin: 0px auto;
  border-radius: 30px;
  padding: 10px;
  border: solid 3px var(--main);
  text-align: center;
  color: gray;
  background-color: white;
}

.search-btn {
  position: absolute;
  left: 2%;
  top: 9px;
  font-size: 33px;
  border: solid 1px #ddd;
  border-radius: 40px;
  padding: 2px;
}

.middle-line {
  border-top: solid 2px #306cfe;
  padding-top: 3px;
  margin-top: 3px;
}


/* megaaaa menuuuu */

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

nav ul,
nav li {
  list-style: none;
  padding: 0;
  margin: 0;
}

nav a {
  display: block;
  text-decoration: none;


}

nav a:hover,
nav a:visited {
  text-decoration: none;
}

.menu-bar {
  display: flex;
  align-items: center;
}

.menu-link {
  padding: 20px 27px;
  color: #000000;
  transition: 0.2s, color 0.2s;
  position: relative;
  z-index: 1;
  border: solid .5px #ddd;
}

.menu-link[aria-haspopup="true"] {
  padding-right: 40px;
}

.menu-link[aria-haspopup="true"]:after {
  content: "";
  background-size: 14px;
  width: 14px;
  height: 14px;
  font-size: 12px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.mega-menu-header {
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: bold;
  color: #136a73;
}

.mega-menu {
  background: #ffffff;
  z-index: 10;
}

.mega-menu--multiLevel {
  flex-direction: column;
}

@media all and (min-width: 951px) {
  .nav>nav {
    min-width: 900px;
  }

  .menu [aria-haspopup="true"]~ul {
    display: none;
  }

  .menu-bar {
    position: relative;
  }

  
  .menu-bar>li>[aria-haspopup="true"]:hover~ul {
    display: flex;
    transform-origin: top;
    animation: dropdown 0.2s ease-out;
  }

  .menu-bar>li>[aria-haspopup="true"]~ul:hover {
    display: flex;
  }

  .menu-bar>li:hover>[aria-haspopup="true"]~ul {
    display: flex;
  }

  /* .menu-bar > li > [aria-haspopup="true"]:focus,
  .menu-bar > li:hover > [aria-haspopup="true"],
  .menu-bar > li:hover > a {
    background: #177E89;
    color: #ffffff;
  } */
  /* .menu-bar > li > [aria-haspopup="true"]:focus:after,
  .menu-bar > li:hover > [aria-haspopup="true"]:after,
  .menu-bar > li:hover > a:after {
    background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1397521/arrowTop.svg#light');
  } */
  .mega-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0px 0px 18px 7px #eaeaea;
}

  .mega-menu:hover {
    display: flex;
  }

  .mega-menu a:hover {
    background: #f6a41369;
    color: #374a4b;
}

  .mega-menu--multiLevel>li {
    width: 33.33333333%;
  }

  .mega-menu--multiLevel>li>[aria-haspopup="true"]~ul {
    left: 33.33333333%;
    width: 33.33333333%;
  }

  .mega-menu--multiLevel>li>[aria-haspopup="true"]~ul ul {
    width: 100%;
    right: 100%;
  }

  .mega-menu--multiLevel li:hover>[aria-haspopup="true"]~ul {
    display: block;
    transform-origin: left;
    animation: flyout 0.2s ease-out;
  }

  .mega-menu--multiLevel li:focus-within>[aria-haspopup="true"]~ul {
    display: block;
  }

  .mega-menu--multiLevel li:hover>[aria-haspopup="true"],
  .mega-menu--multiLevel li:focus-within>[aria-haspopup="true"],
  .mega-menu--multiLevel li:hover>a,
  .mega-menu--multiLevel li:focus-within>a {
    background: #f6a41369;
    color: #136a73;
  }

  .mega-menu--multiLevel [aria-haspopup="true"]~ul,
  .mega-menu--multiLevel [aria-haspopup="true"] {
    border-left: 1px solid #f0f0f0;
  }

  .mega-menu--multiLevel [aria-haspopup="true"]~ul:hover,
  .mega-menu--multiLevel [aria-haspopup="true"]:hover {
    display: block;
  }

  .mega-menu--multiLevel [aria-haspopup="true"]~ul {
    position: absolute;
    top: 0;
    height: 100%;
  }

  .mega-menu--flat>* {
    flex: 1;
  }

  .mobile-menu-trigger,
  .mobile-menu-header,
  .mobile-menu-back-item {
    display: none;
  }
}

@keyframes dropdown {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes flyout {
  0% {
    opacity: 0;
    transform: scaleX(0);
  }

  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}
.border-none {
  border: none;
}
/* megaaaa menuuuu */

/* phone header */
.header-phone {
  box-shadow: -1px 2px 20px 0px #ddddddc2;
  position: sticky;
  top: 0px;
  z-index: 999;
}

.logo-phone {
  box-shadow: 20px 0px 9px 9px #ddd;
}

.login-btn {
  box-shadow: -20px 0px 9px 9px #ddd;
  padding: 11px 20px;
}
.mega-menu-phone {
  width: 70% !important;
}
.accordion-button:not(.collapsed) {
  background-color: white  !important;
  border-bottom: solid 1px #ddd;

}
.accordion-button:focus {
  box-shadow: none !important;
}

button.accordion-button.collapsed {
  padding-right: 11px !important;
}

.search-input-m {
  margin: 0px auto;
  border-radius: 30px;
  padding: 10px;
  border: solid 1px var(--main);
  text-align: start;
  color: gray;
  background-color: white;
  font-size: 10px;
  background-image: linear-gradient(45deg, #f6a3138e, transparent);
}

.search-box-content {
width: 95%;
background-color: white;
border-radius: 15px;
border: solid 1px #F6A413;
margin: -15px auto;
padding: 10px;
}

.search-card {
background: linear-gradient(0deg, #ddd, white);
padding: 12px;
border-radius: 10px;
}

.cart-count-badge {
  position: absolute;
  bottom: -3px;
  right: 6px;
  color: rgba(255, 0, 0, 0.864);
  padding: 5px 10px;
  font-size: 17px;
}

@media only screen and (max-width: 600px) {
    .sidbar-account-item {
        font-size: 10px;
    }
    .sidbar-account-title {
        font-size: 12px !important;
    }
}
.account-sidbar {
    border-left: solid 2px #F6A413;
}


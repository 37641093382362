.tracing-code-box {
    border-radius: 10px;
    padding: 25px;
    text-align: center;
}

.traking-input {
    width: 80%;
    border: solid 1px #F6A413;
    background-color: white;
    border-radius: 7px;
    padding: 5px;
}

.check-traking {
    background-color: green;
    margin-top: 20px;
    border-radius: 5px;
    border: none;
    padding: 5px 30px;
    color: white;
}

.img-traking {
    width: 75%;
}
.order-item-traking {
    width: 100%;
}

.traking-table td {
    background-color: white;
}



.dot {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #ddd;
}

.dot-green {
    background-color: green;
}

.active-trak {
    border: solid 3px green;
}

.img-trak {
    border-radius: 50%;

}


.elementor.elementor-21873.e-loop-item {
    z-index: 999 !important;
}

@media only screen and (max-width: 700px) {
    .img-traking {
        width: 60%;
        margin-top: -25px;
    }
 
    .box-dot {
            display: grid !important;
            justify-content: space-around !important;
            margin-right: -65px !important;
            margin-top: 25px !important;
            text-align: center;
    }
    .dot {
        margin: 10px 0px;
    }
}
@media only screen and (max-width: 600px) {

    .baner {
        height: 123px !important;
        margin-top: -0px;

    }
    .blg-crsl {
        margin-top: 0px !important;
    }
    
    .product-title-cart {
        font-size: 10px !important;
    }

    .arow {
        width: 30px !important;
    }

    .arow2 {
        width: 21px !important;
    }

    .baner2-title {
        font-size: 29px ! IMPORTANT;
        -webkit-text-stroke: 2px;
    }

    .title-baner-1 {
        right: auto;
        border-radius: 10px !important;
        padding: 10px 8px !important;
        top: 12% !important;
        color: white;

    }

    .title-baner-2 {
        top: 50% !important;
        right: 6% !important;
        font-size: 13px !important;
    }

    .section-one {
        margin-top: 0px !important;
    }

    .about-remap {
        margin-top: 30px !important;
        font-size: 20px !important;
    }

    .about-remap::before {
        content: "";
        width: 40% !important;
        right: 40% !important;
        height: 3px;
    }

    .time-remap {
        margin-top: 30px;
        font-size: 20px !important;
    }

    .time-remap::before {
        content: "";
        width: 40% !important;
        right: 43% !important;
        height: 3px;
    }

    .sec-title {
        font-size: 14px !important;
        margin-top: 13px;
    }

    .circle-box {
        max-width: 66px !important;
        padding: 14px 5px 12px 5px !important;
    }

    .circle-box-prof {
        max-width: 66px !important;
        padding: 14px 5px 12px 5px !important;
    }

    .client-baner {
        border-radius: 20px 0px 0px 20px;
    }

    .circle-box {

        left: -15% !important;
    }

    .blog-baner {
        max-width: 60%;
        text-align: center;
        margin: 0px auto;
    }

    .blog-box {
        height: 500px !important;
    }


}

@media screen and (max-width: 1030px) and (min-width: 600px) {
    .baner2-title {
        font-size: 25px ! IMPORTANT;
        -webkit-text-stroke: 2px;
    }

    .title-baner-1 {
        right: auto;
        border-radius: 10px !important;
        padding: 10px 8px !important;
        top: 12% !important;
        color: white;

    }

    .baner {
        height: 339px !important;
        margin-top: -0px;

    }

    .title-baner-2 {
        top: 50% !important;
        right: 15% !important;
        font-size: 25px !important;
    }

    .about-remap {
        margin-top: 30px !important;
        align-items: center;
        font-size: 25px;
        position: relative;
    }

    .about-remap::before {
        content: "";
        right: 66% !important;
    }

    .time-remap {
        margin-top: 30px;
        font-size: 25px;
    }

    .time-remap::before {
        content: "";
        right: 70% !important;
    }

    .blog-box {
        height: 260px !important;
    }

    .circle-box {
        left: -5% !important;
    }
}

@media screen and (max-width: 1440px) and (min-width: 1030px) {
    .baner {
        height: 478px !important;
        margin-top: -0px;

    }
}

.section-one {
    margin-top: -5px;
}

.blg-crsl {
    margin-top: 24px;
}

.baner {
    height: 550px;
    background-image: url(../../../public/image/upg-baner\ 1.jpg);
    background-color: white;
    background-size: contain;
    background-position: center right;
    background-repeat: no-repeat;
}

.baner2-title {
    font-size: 62px;
    -webkit-text-stroke: 2px;
}

.boxbaner {
    position: relative;
}

.title-baner-1 {
    position: absolute;
    top: 16%;
    right: auto;
    background-color: #000052;
    border-radius: 30px;
    padding: 10px 25px;
    color: white;

}

.title-baner-2 {
    position: absolute;
    top: 50%;
    right: 17%;
    text-align: center;
    color: #000052;
    font-size: 34px;
}

.about-remap {
    margin-top: 100px;
    align-items: center;
    font-size: 25px;
    position: relative;
}

.about-remap::before {
    content: "";
    width: 60%;
    right: 36%;
    height: 3px;
    position: absolute;
    border-radius: 30px;
    background-image: linear-gradient(to right, white, #f6a413);
}

.time-remap {
    margin-top: 30px;
    align-items: center;
    font-size: 25px;
    position: relative;
}

.time-remap::before {
    content: "";
    width: 60%;
    right: 43%;
    height: 3px;
    position: absolute;
    border-radius: 30px;
    background-image: linear-gradient(to right, white, #f6a413);
}

.sec-title {
    font-size: 25px;
}

.circle-box {
    position: absolute;
    background-color: white;
    border-radius: 50%;
    border: solid 4px #f6a413;
    max-width: 95px;
    padding: 22px 5px 25px 5px;
    top: 40%;
    left: -6%;
}

.review-slider-box {
    background-color: white;
    border-radius: 0px 25px 25px 0px;
}

.client-baner {
    background-image: url(../../../public/image/reviwe.png);
    background-size: cover;
    background-position: center center;
}

.blog-box {
    background-image: linear-gradient(#F4F4F4, #DBDBDB);
    height: 370px;
    border-radius: 25px;
}


.arow {
    width: 40px;
}

.arow2 {
    width: 30px;
}


.product-title-cart {
    font-size: 14px;
}

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff; /* or any background color of your choice */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Make sure it's on top of everything */
  }
  
  .preloader img {
    width: 10%; /* Adjust the size of your loader GIF */
  }
  
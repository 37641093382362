@media only screen and (max-width: 820px) {
    
    
}



.pardakht-input {
    background-color: transparent;
    border: none;
    border-bottom: solid 2px #b4b4b4;
    transition: all 1s;
    max-width: 100%;
    border-radius: 0px !important;
}

.pardakht-input-lable {
    border-right: solid 2px #F6A413;
    padding-right: 7px;
}

.pardakht-input:focus-visible {
    outline: none !important;
    transition: all 1s;
    background-image: linear-gradient(transparent, #f6a41347);
}

.pardakht-input fieldset {
    border: none;
    min-width: 180px;
}
.pardakht-input div#demo-simple-select-filled {
    border-radius: 0px;
    min-width: 133px;
    padding: 1px 24px;
}



.countine-btn {
    background-color: rgb(0, 161, 0);
    border-radius: 8px;
    color: white;
    width: 100%;
    border: none;
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 8px 15px !important;
    text-align: start;
    font-size: 18px;
}


.img-tab .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
    justify-content: space-around;
}

.img-tab span.MuiTabs-indicator.css-11yukd5-MuiTabs-indicator {
    background: #F69F13 !important;
}
@media screen and (max-width: 1030px) and (min-width: 600px) {
    
}
@media only screen and (min-width: 1030px) {

    .review-swiper {
        min-width: 730px;
        margin-right: -50px;
    }

    .box-review {
        width: 475px;
    }
}

@media only screen and (max-width: 600px) {
    .review {
        font-size: 15px;
        font-weight: 200;
        color: black;
    }
    .client-name {
        font-size: 18px;
    }
    .review-parent {
        height: 240px !important;
    }
    button.review-prev {
        top: 3px !important;
    }
    
    button.review-next {
        top: 90% !important;
    }
}


.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.circle-box-prof {
    position: absolute;
    max-width: 115px;
    top: 37%;
    right: 1%;
    z-index: 99;
}

button.review-prev {
    position: absolute;
    top: 29px;
    right: 50%;
    z-index: 2;
    border: none;
    background: transparent;
}

button.review-next {
    position: absolute;
    top: 80%;
    right: 50%;
    z-index: 2;
    border: none;
    background: transparent;
}
.review-parent {
    height: 320px;
}

.img-prf {
    max-width: 90px;
    border-radius: 50%;
}
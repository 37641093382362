

.header-accr {
    border: none !important;
    border-radius: 0px !important;
    background-color: transparent;
    border-right: solid 2px #F6A413 !important;
}

.header-accr h2 button {
    background-color: transparent;
    padding: 6px;
}




.header-accr h2 button:not(.collapsed) {
    background-color: transparent !important;
    border-bottom: none !important;
}


.swich-box {
    background-image: linear-gradient(45deg, rgba(211, 211, 211, 0), #fbdeb4);
    border-radius: 9px;
    padding: 0px 10px;
    font-size: 15px;
}

.sort-select div {
    padding: 10px 45px  !important;
}
.filters-box {
    position: relative;
}

.filters-box::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #F6A413;
    top: 140%;
    right: 0px;
}

.sort-select fieldset {
    border: none;
    background-image: linear-gradient(45deg, rgba(211, 211, 211, 0), #fbdeb4);
    z-index: -1;
    border-radius: 9px;
}




@media only screen and (max-width: 700px) {
    .sort-select div {
        padding: 0px 25px  !important;
    }
    .pagination button {
        background: #ddd;
        margin: 0px 2px !important;
    }
    .title-shop-page {
        font-size: 15px;
        margin: 0px;
    }
}

.pagination {
    justify-content: center;
}

.pagination button {
    background: #ddd;
    margin: 0px 11px;
}

.pagination .Mui-selected {
    background-color: white !important;
    border: solid 1px #F6A413 !important;
}

.pagination button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-text.MuiPaginationItem-circular.MuiPaginationItem-previousNext.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
    background: transparent !important;
}

.title-shop-page {

}
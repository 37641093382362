.contact-boxes {
    background-color: white;
    padding: 25px;
    border-radius: 10px;
}

.contact-box-title {
    border-right: solid 2px #f6a413;
    padding-right:10px ;
}

.number-box {
    background: linear-gradient(45deg, #ff0901, #F69F13);
    border-radius: 17px;
    color: white;
    margin: 42px 15px;
}

.instagram-box {
    background: linear-gradient(45deg, #ff0901, #F69F13);
    border-radius: 17px;
    color: white;
    margin: 42px 15px;
}

.youtub-box {
    background: linear-gradient(45deg, #ff0901, #F69F13);
    border-radius: 17px;
    color: white;
    margin: 42px 15px;
}

.aparat-box {
    background: linear-gradient(45deg, #ff0901, #F69F13);
    border-radius: 17px;
    color: white;
    margin: 42px 15px;
}



.video-box {
    background-color: white;
    padding: 25px;
    border-radius: 10px;
}
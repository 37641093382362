@media only screen and (max-width: 650px) {
    .slidee2 {
        height: 300px !important;
    }

    .product-card2 {
        height: 300px !important;
        padding: 7px !important;

    }

    .slidee {
        height: 245px !important;
    }

    .product-card {
        height: 245px !important;
        padding: 7px !important;

    }

    .slidee2 {
        height: 285px !important;
    }

    .product-card2 {
        height: 300px !important;
        padding: 7px !important;

    }

    .slidee::after {
        width: 100% !important;
    }

    .cart-btn {
        padding: 7px 7px 7px 7px !important;
    }

    .f-link {
        font-size: 25px !important;
    }

    .f-links {
        font-size: 12px !important;
    }

    .links-card {
        margin: 0px 6px !important;
    }

    .links-card {
        background-color: #F6A413 !important;
        color: white !important;
        padding: 2px !important;
    }
}

@media screen and (max-width: 1430px) and (min-width: 1024px) {
    .slidee {
        height: 393px !important;
    }
}


.swiper {
    width: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-img {
    max-width: 280px;
    z-index: 10;
}

.product-card {
    padding: 20px;
    border-radius: 20px;
    height: 435px;
}

.cart-btn {
    border: none;
    background-color: transparent;
}

.slidee::after {
    content: "";
    position: absolute;
    width: 100%;
    margin: auto;
    inset: 0;
    border-radius: 20px;
    padding: 3px;
    background: linear-gradient(91deg, #ffffff00, #F6A413);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    mask-composite: exclude;
    z-index: -10;
}

.slidee {
    height: 442px;
    padding: 0px 4px;
    position: relative;
}

.cart-btn {
    padding: 6px 14px 5px 14px;
    background: #000052;
    border-radius: 10px;
    color: white;
}

/* //////////////////////////////////// */

.slider-one-next {
    border: none;
    border-radius: 50%;
    background-color: #F6A413;
    color: white;
    padding: 4px 8px;
    margin: 0px 5px;

}

.slider-one-prev {
    border: none;
    border-radius: 50%;
    background-color: #F6A413;
    color: white;
    padding: 4px 8px;
    margin: 0px 5px;
}

.links-card {
    background-color: white;
    display: flex;
    align-items: center;
    color: #F6A413;
    border-radius: 50%;
    margin: 0px 10px;
    padding: 3px;
}

.f-link {
    font-size: 35px;
}

.main-price {
    color: red;
    text-decoration: line-through;
    font-size: 15px;
}
@font-face {
  font-family: "YekanBakh-Heavy";
  src: url("./../public/Fonts/YekanBakh-Bold/YekanBakh-Bold.eot");
  src: 
    url("./../public//Fonts/YekanBakh-Bold/YekanBakh-Bold.woff2")format("woff2"),
    url("./../public/Fonts/YekanBakh-Bold/YekanBakh-Bold.woff")format("woff"),
    url("./../public/Fonts/YekanBakh-Bold/YekanBakh-Bold.ttf")format("truetype"),
    url("./../public/Fonts/YekanBakh-Bold/YekanBakh-Bold.svg")format("svg");
}

@font-face {
  font-family: "YekanBakh-Heavy";
  font-weight: 200;
  src: url("./../public/Fonts/YekanBakh-Light/YekanBakh-Light.eot");
  src:
    url("./../public/Fonts/YekanBakh-Light/YekanBakh-Light.woff2")format("woff2"),
    url("./../public/Fonts/YekanBakh-Light/YekanBakh-Light.woff2")format("woff"),
    url("./../public/Fonts/YekanBakh-Light/YekanBakh-Light.ttf")format("truetype"),
    url("./../public/Fonts/YekanBakh-Light/YekanBakh-Light.svg")format("svg");
}


.container-fluid {
  max-width: 1920px !important;
}

:root {
  --main: #F6A413;
}

body {
  background-color: #F4f4f4;

}

.main-color {
  color: var(--main) !important;
}

.bg-blue {
  background-color: var(--main) !important;

}

.line {
  height: 2px;
  margin: 5px auto;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 3%, rgba(171, 171, 171, 1) 52%, rgba(255, 255, 255, 0) 97%);
}

.py-4 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

p,
span,
button,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
a,
div {
  font-family: "YekanBakh-Heavy"  !important;
}

.fs-7 {
  font-size: 13px;
}



@media only screen and (min-width: 830px) {
  .gx-lg-6 {
    --bs-gutter-x: 6rem;
  }


  .fs-8 {
    font-size: 13px !important;
  }

  .logo-footer {
    font-size: 40px !important;
  }

  .F-category li::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 80%;
    top: 1px;
    right: -8px;
    background: linear-gradient(to top, white, var(--main), white);
  }

  .fs-0 {
    font-size: 48px !important;
    -webkit-text-stroke: 2px;
  }

  .fs-lg-2 {
    font-size: 2rem !important;
  }

}

@media only screen and (max-width: 700px) {
  .logo-footer {
    -webkit-text-stroke: 0px;
  }

  .khadamat {
    max-height: 110 !important;
  }

  .fs-8 {
    font-size: 9px !important;
  }

  .F-phone::after {
    right: 0px !important;
  }

  .footer {
    border-radius: 0px !important;
    border-top: solid 2px #F6A413;
  }

  .phone-line {
    position: relative;
  }

  .phone-line::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 100%;
    top: 1px;
    left: 0px;
    background: linear-gradient(to top, white, #F6A413, white);
  }

  .F-category li {
    font-size: 11px;
  }

  .fs-sm-7 {
    font-size: 12px !important;
  }

  .fs-0 {
    font-size: 15px;
  }

}

.bg-light {
  background-color: #FAFAFA !important;
}

@media only screen and (min-width: 1400px) {
  .fs-0 {
    font-size: 70px !important;
    -webkit-text-stroke: 2px;
  }
}


.nav-links {
  color: gray;
  font-weight: 200;
}

.nav-links:hover {
  color: black;
}
@media only screen and (max-width: 820px) {
    .card-blog-content {
        font-weight: 300;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3 !important; /* number of lines to show */
                line-clamp: 3 !important; 
        -webkit-box-orient: vertical;
        font-size: 12px;
    }
    .card-blog-title {
        font-size: 13px;
    }
    .img-blog-card {
        max-width: 50px !important;
        text-align: end;
        background-color: white;
        border-radius: 50%;
        padding: 12px 0px;
        border: solid 1px #F6A413;
        box-shadow: -2px 3px 20px 6px #ddd;
    }
    
}

.img-blog-card {
    max-width: 73px;
    text-align: end;
    background-color: white;
    border-radius: 50%;
    padding: 18px 0px;
    border: solid 1px #F6A413;
    box-shadow: -2px 3px 20px 6px #ddd;
}

.card-blog-content {
    font-weight: 300;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
            line-clamp: 4; 
    -webkit-box-orient: vertical;
}

.card-blog-arrow {
        width: 17px;
        margin-right: 8px;
}

.box-card-blog {
    background-color: white;
    border-radius: 15px;
    position: relative;
}

.box-card-blog-border {
    position: relative;
    padding: 3px 16px;
}

.box-card-blog-border::after {
    content: "";
    position: absolute;
    width: 100%;
    margin: auto;
    inset: 0;
    border-radius: 20px;
    padding: 3px;
    background: linear-gradient(91deg, #ffffff00, #F6A413);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    mask-composite: exclude;
    z-index: -10;
}


.cart-blog-photo {
    max-height: 150px;
}
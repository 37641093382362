@media only screen and (max-width: 890px) {
    .slide-wish {
        height: 250px !important;
        padding: 0px 4px;
        position: relative;
}
}

.slide-wish::after {
        content: "";
        position: absolute;
        width: 100%;
        margin: auto;
        inset: 0;
        border-radius: 20px;
        padding: 3px;
        background: linear-gradient(91deg, #ffffff00, #F6A413);
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        mask-composite: exclude;
        z-index: -10;
}

.slide-wish {
        height: 410px;
        padding: 0px 4px;
        position: relative;
}
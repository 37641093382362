.order-number {
    width: 15%;
}

.order-item {
    width: 45%;
}

.order-date {
    width: 20%;
}

.order-price {
    width: 20%;
}

.order-header {
    margin-bottom: 25px;
    border-bottom: solid 2px #F6A413;
}


.order-item-box-2 {
    border-bottom: solid 2px #ddd;
    line-height: 22px !important;
}

@media only screen and (min-width: 1024px) {
    .order-item-box {
        border-bottom: solid 2px #ddd;
    }
}

.order-item-phone {
    font-size: 10px;
    text-align: start;
}
.interdues {
    position: relative;
}

.interdues::after {
    content: "";
    height: 2px;
    background-color: #F6A413;
    width: 100%;
    position: absolute;
    top: 150%;
    right: 0px;

}

.advertise-slide {

    background-image: linear-gradient(50deg, #dfd4d400, #d5d5d5);
    padding: 15px 1px;
    border-radius: 8px;

}

.advertise-slide .swiper-pagination {
    top: 96% !important;
}

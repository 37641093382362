.service-box {
    max-height: 450px;
    min-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
}

.service-row {
    background: linear-gradient(45deg, white, #f6a31341);
}

.service-box::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: white;
}

.service-box::-webkit-scrollbar
{
	width: 5px;
	background-color: white;
}

.service-box::-webkit-scrollbar-thumb
{
	background-color: #F6A413;
}

.active-service-row {
    background: linear-gradient(45deg, white, #f6a313de);

}

.res-next-step {
    background-color: #0eab00;
    color: white;
    padding: 6px 15px;
    border-radius: 8px;
    font-size: 13px;
}
.res-prev-step {
    background-color: #ddd;
    color: black;
    padding: 6px 15px;
    border-radius: 8px;
    font-size: 13px;
}
@media only screen and (max-width: 600px) {
    .title-details {
        font-size: 13px;
    }
    .details-prof {
        max-width: 35px !important;
    }
}

.details-prof {
    max-width: 50px;
}
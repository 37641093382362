.attr-btn {
    padding: 4px 10px;
    border: solid 1px #ddd;
    border-radius: 5px;
    background-color: white;
}

.box-border {
    border-left: solid 2px #f6a413;
    border-right: solid 2px #f6a413;
    padding: 0px 15px;
}

.single-btns {
    background: white;
    padding: 7px 15px;
    font-size: 26px;
    border-radius: 15px;
    box-shadow: 0px 0px 8px 0px #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.swiper-slide {
    background-size: cover;
    background-position: center;
  }
  
  .album2 {
    width: 100%;
  }
  
  .album {
    box-sizing: border-box;
    padding: 10px 0;
  }
  
  .album .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }
  
  .album .swiper-slide-thumb-active {
    opacity: 1;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  


@media screen and (max-width: 1030px) and (min-width: 600px) {
    .about-baner {
        min-height: 400px !important;
    }
    .upgraders {
        position: absolute;
        max-height: 210px !important;
        top: 31px !important;
        left: 13px;
    }
    .title-about-baner {
        font-size: 30px !important;
        font-weight: bold;
        -webkit-text-stroke: 3px;
    }
}

.about-baner {
    background-image: url("../../Image/about-banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom left;
    min-height: 485px;
}

.title-about-baner {
    font-size: 50px;
    font-weight: bold;
    -webkit-text-stroke: 3px;
}

.upgraders {
    position: absolute;
    max-height: 249px;
    top: -31px;
    left: 13px;
}
.interdus-upgrader-box {
    position: relative;
    margin-top: -20px;
    margin-bottom: 150px;

}

.abslut-title {
    position: absolute;
    font-size: 36px;
    top: -69px;
    background: #fafafa;
    padding: 10px 40px;
    border-radius: 5px 5px 0px 5px;
    right: 0px;
}


/* padding: 23px 45px;
    margin: 0px 70px;
    background: linear-gradient(0deg, #c4c4c482, transparent);
    border: none;
} */

.tab-content-custom {
    background: linear-gradient(0deg, transparent, #c4c4c4);
    padding: 20px;
    border-radius: 10px;
}



.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background: linear-gradient(0deg, #c4c4c4, transparent) !important;
    border: none !important;
    padding: 31px 45px !important;
}


.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
    border: none !important;
    padding: 31px 45px !important;
}

.img-tab-head {
    max-width: 96px;
    min-height: 102px;
    max-height: 80px;
}







@media only screen and (max-width: 600px) {
    .about-baner {
        min-height: 167px !important;
    }
    .title-about-baner {
        font-size: 14px !important;
        -webkit-text-stroke: 1px !important;
    }
    .upgraders {
        position: absolute;
        max-height: 120px !important;
        top: 66px !important;
        left: 6px !important;
    }
    .abslut-title {
        font-size: 16px !important;
        top: -35px !important;
        padding: 7px 20px !important;
    }
    .lft-title {
        font-size: 11px;
        text-align: right !important;
    }
    .interdus-upgrader-box {
        position: relative;
        margin-top: 3px !important;
        margin-bottom: 50px !important;
    
    }
    .img-tab-head {
        max-width: 50px !important;
        min-height: 74px !important;

    }
    li.nav-item {
        max-width: 25%;
    }
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
        background: linear-gradient(0deg, #c4c4c4, transparent) !important;
        border: none !important;
        padding: 31px 10px !important;
    }
    
    
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
        border: none !important;
        padding: 31px 10px !important;
    }
}

.tab-content {
    margin-top: -10px;
}
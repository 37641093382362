.bg-login-sec {
    padding: 5%;
    min-height: 100vh;
    max-height: 100vh;
}

.login-sec {
    background-image: url(../../Image/bg-login-baner.jpg);
    background-size: contain;
    background-position: center center;
    background-color: white;
    box-shadow: 0px 0px 12px -5px #ddd;
    padding: 40px;
    border-radius: 15px;
    background-repeat: no-repeat;

}

.bg-login-baner {
    background-image: url(../../Image/car-login.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    height: 550px;
    position: relative;
}

.welcome-to-shop {
    position: absolute;
}

.title-login {
    font-size: 80px;
    color: #F6A413;
    -webkit-text-stroke: 3px;
}

.login-form {
    backdrop-filter: blur(7px);
    border: solid 1px #ddd;
    border-radius: 15px;
}

.login-input {
    padding: 5px;
    border-radius: 10px;
    border: solid 1px #ddd;
    width: 70%;
    text-align: center;

}

.send-otp-button {
    background-color: green;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 4px 30px;
}
.sendagain-otp-button {
    background-color: green;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 4px 30px;
    width: 70%;
}

.sendagain-otp-button:disabled {
    opacity: .5;
}

.confirm-otp {
    position: absolute;
    left: 75px;
    top: 0px;
    padding: 6px 20px;
}

@media only screen and (min-width: 800px) and (max-width: 950px) {}


@media only screen and (max-width: 800px) {
    .bg-login-baner {
        background-image: url(../../Image/bg-login-mb.png);
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        height: 250px;
        position: relative;
    }
    .bg-login-sec {
        padding: 0%;
    
        min-height: 100vh;
        max-height: 100vh;
    }
    .login-sec {
  
        padding: 10px;
 
    }
    .title-login {
        font-size: 50px;
        color: #F6A413;
        -webkit-text-stroke: 2px;
    }
    .confirm-otp {
        left: 37px;
    }
    
    
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
